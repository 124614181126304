.bot-message {
  font-size: 15px;
  color: #656AD3;
  background-color: #F2F3FF;
  margin-bottom: 6px;
  min-height: 40px;
  border-radius: 20px;
  padding: 10px 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 4px;
  position: relative; }
  @media screen and (min-width: 720px) {
    .bot-message {
      font-size: 16px; } }
  .bot-message__emoji {
    width: 2em;
    display: inline-block; }
  .bot-message__label {
    display: inline-block;
    margin-right: auto;
    line-height: 1;
    max-width: 220px; }
  .bot-message__bot-speaks {
    /*font-style: italic;*/ }
  .bot-message__actions {
    margin-left: .4em; }
  .bot-message__separator {
    color: rgba(101, 106, 211, 0.5);
    margin: 0 .1em; }
