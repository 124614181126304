.filter-meta {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  line-height: 1; }
  .filter-meta__emoji {
    width: 2em; }
  .filter-meta__label span {
    width: 198px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block; }
  .filter-meta__question {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.78); }

.applied-filters {
  -webkit-box-flex: 1;
      -ms-flex: auto;
          flex: auto;
  border-color: #fff;
  -webkit-transition: 300ms ease-in-out;
  transition: 300ms ease-in-out; }
  .applied-filters__filter {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    min-height: 40px;
    height: 1px;
    border-radius: 20px;
    padding: 0 18px;
    margin-bottom: 4px;
    position: relative;
    font-size: 15px;
    -webkit-animation: pop-down 600ms linear;
            animation: pop-down 600ms linear;
    -webkit-transition: background-color 200ms;
    transition: background-color 200ms;
    background-color: #2084d8;
    color: #fff; }
    @media screen and (min-width: 720px) {
      .applied-filters__filter {
        font-size: 16px; } }
    .applied-filters__filter--preset {
      background-color: #FEE27F;
      color: #2d3644; }
    .applied-filters__filter--excluding {
      background-color: #FF5A14; }
  .applied-filters__preset-container {
    -webkit-transition-duration: 250ms;
            transition-duration: 250ms;
    border-radius: 26px;
    border: 2px solid rgba(128, 128, 128, 0.25);
    padding: 6px;
    padding-bottom: 2px;
    margin-bottom: 6px; }

@-webkit-keyframes dropIn {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -10px, 0) scale(0.8);
            transform: translate3d(0, -10px, 0) scale(0.8); }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0) scale(1);
            transform: translate3d(0, 0, 0) scale(1); } }

@keyframes dropIn {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -10px, 0) scale(0.8);
            transform: translate3d(0, -10px, 0) scale(0.8); }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0) scale(1);
            transform: translate3d(0, 0, 0) scale(1); } }

@-webkit-keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3); }
  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1); } }

@keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3); }
  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1); } }
