.filter-panel {
  z-index: 1; }
  .filter-panel__add {
    font-size: 14px;
    margin-bottom: 6px;
    padding: 6px 0; }
  .filter-panel__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }
