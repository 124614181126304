.license-expired {
  max-width: 100%;
  margin: 0 auto;
  padding: 48px 24px; }
  @media (min-width: 960px) {
    .license-expired {
      width: 1200px; } }
  .license-expired--small {
    width: 100%;
    padding: 12px 12px;
    color: #FF5A14;
    text-align: center; }
    .license-expired--small h3 {
      margin-bottom: 0; }
  .license-expired__blocks {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    @media (min-width: 720px) {
      .license-expired__blocks {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row; } }
  .license-expired__text-block {
    width: 100%; }
    @media (min-width: 720px) {
      .license-expired__text-block {
        width: 45%; } }
  .license-expired__figure {
    width: 100%; }
    @media (min-width: 720px) {
      .license-expired__figure {
        width: 55%;
        padding-right: 24px; } }
