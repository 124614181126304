.Draftail-Icon {
  fill: currentColor;
  pointer-events: none;
  vertical-align: middle; }

.Draftail-Editor {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px; }
  .Draftail-Editor--hide-placeholder .public-DraftEditorPlaceholder-root {
    display: none; }
  .Draftail-Editor--readonly {
    pointer-events: none; }
    .Draftail-Editor--readonly .DraftEditor-editorContainer {
      opacity: 0.5; }
      .Draftail-Editor--readonly .DraftEditor-editorContainer::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 3; }
  .Draftail-Editor .DraftEditor-root {
    color: #333;
    font-size: 1rem;
    line-height: 1.5;
    font-family: "Brandon Text";
    -webkit-font-variant-ligatures: none;
            font-variant-ligatures: none;
    overflow: auto; }
  .Draftail-Editor .public-DraftEditor-content,
  .Draftail-Editor .public-DraftEditorPlaceholder-root {
    padding: 0.75rem; }
  .Draftail-Editor .public-DraftEditor-content > * > figure {
    margin: 0; }
  .Draftail-Editor .public-DraftEditorPlaceholder-inner {
    color: #525760; }

.Draftail-block--unstyled,
.Draftail-Editor .public-DraftEditorPlaceholder-inner {
  margin: 0.5rem 0; }

.Draftail-Toolbar {
  position: relative;
  padding: 0.25rem;
  background-color: #ddd;
  color: #333;
  border-radius: 0;
  border-bottom: 1px solid #ddd; }
  .Draftail-Editor--focus .Draftail-Toolbar {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 4; }

.Draftail-ToolbarGroup {
  display: inline-block; }
  .Draftail-ToolbarGroup + .Draftail-ToolbarGroup::before {
    content: "";
    display: inline-block;
    width: 1px;
    height: 1rem;
    vertical-align: middle;
    margin: 0 0.125rem;
    background-color: #c4c4c4; }
  .Draftail-Editor--readonly .Draftail-ToolbarGroup {
    opacity: 0.5; }

.Draftail-ToolbarButton {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  padding: 0.5rem;
  border-radius: 0;
  background: transparent;
  color: inherit;
  border: 1px solid transparent;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .Draftail-ToolbarButton--active {
    background-color: rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.2); }
  .Draftail-ToolbarButton__label {
    display: inline-block;
    vertical-align: middle;
    height: 1em; }

button[data-draftail-balloon] {
  overflow: visible; }

[data-draftail-balloon] {
  position: relative;
  cursor: pointer; }
  [data-draftail-balloon]::before, [data-draftail-balloon]::after {
    position: absolute;
    z-index: 5;
    opacity: 0;
    pointer-events: none; }
  [data-draftail-balloon]::before {
    content: ""; }
  [data-draftail-balloon]::after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: rgba(17, 17, 17, 0.9);
    border-radius: 4px;
    color: #fff;
    content: attr(aria-label);
    padding: 0.5em 1em;
    white-space: pre;
    font-size: 0.875em; }
  [data-draftail-balloon]:hover::before, [data-draftail-balloon]:hover::after {
    opacity: 1;
    -webkit-transition: all 0.1s ease-out 1s;
    transition: all 0.1s ease-out 1s; }
  [data-draftail-balloon]::after {
    left: 50%;
    margin-top: 7px;
    top: 100%;
    -webkit-transform: translate(-50%, -10px);
            transform: translate(-50%, -10px); }
  [data-draftail-balloon]::before {
    background: no-repeat url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http://www.w3.org/2000/svg%22%20width%3D%2236px%22%20height%3D%2212px%22%3E%3Cpath%20fill%3D%22rgba(17, 17, 17, 0.9)%22%20transform%3D%22rotate(180 18 6)%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E");
    background-size: 100% auto;
    width: 18px;
    height: 6px;
    left: 50%;
    margin-top: 1px;
    top: 100%;
    -webkit-transform: translate(-50%, -10px);
            transform: translate(-50%, -10px); }
  [data-draftail-balloon]:hover::after {
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0); }
  [data-draftail-balloon]:hover::before {
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0); }

.Draftail-DividerBlock {
  border: 0;
  background: #ddd;
  height: 1px;
  margin: 10px 0; }

.Draftail-Editor .DraftEditor-root {
  max-height: 500px; }
