.row, .user-list__actions--row, .user-list__actions--remove-row {
  padding: 7.2px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  border-bottom: solid 1px rgba(128, 128, 128, 0.25);
  cursor: pointer;
  -webkit-transition: background-color 200ms;
  transition: background-color 200ms; }

.user-list__item {
  -webkit-animation: none;
          animation: none;
  border-bottom: 4px solid #f5f7f9;
  background-color: rgba(255, 255, 255, 0.8); }
  .user-list__item:nth-child(even) {
    background-color: rgba(255, 255, 255, 0.3); }
  .user-list__item--header {
    display: none; }
    .user-list__item--header .user-list__section {
      padding-top: 24px;
      padding-bottom: 24px; }
  @media (min-width: 720px) {
    .user-list__item {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; } }

.user-list__title {
  font-size: 19px;
  line-height: 1.3;
  margin: 0; }

.user-list__details--name {
  font-family: "Brandon Text", sans-serif;
  font-weight: 700;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }

.user-list__details--email {
  font-size: 15px; }
  @media screen and (min-width: 720px) {
    .user-list__details--email {
      font-size: 16px; } }

.user-list__details--last-login {
  font-size: 15px; }
  @media screen and (min-width: 720px) {
    .user-list__details--last-login {
      font-size: 16px; } }

.user-list__details--role {
  font-size: 15px; }
  @media screen and (min-width: 720px) {
    .user-list__details--role {
      font-size: 16px; } }

.user-list__single-item {
  display: block; }

.user-list__button--button-container {
  width: 100%; }
  .user-list__button--button-container button {
    outline: none; }

.user-list__button--button-text {
  font-family: "Brandon Text", sans-serif;
  font-weight: 700;
  text-transform: none;
  text-align: left; }

.user-list__button--arrow {
  float: right;
  margin-top: -1px; }

.user-list__actions--dropdown {
  position: absolute;
  z-index: 10;
  background: #fff;
  -webkit-box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.15);
          box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  left: 24px;
  top: 57px;
  width: 264px; }

.user-list__actions--row {
  padding: 9.708px 0 10.8px; }
  .user-list__actions--row:hover {
    background-color: rgba(32, 132, 216, 0.05); }

.user-list__actions--tick-box {
  width: 19px;
  margin: 0 12px;
  margin-top: 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

.user-list__actions--action-name {
  font-family: "Brandon Text", sans-serif;
  font-weight: 700;
  font-size: 15px;
  color: #2d3644; }
  @media screen and (min-width: 720px) {
    .user-list__actions--action-name {
      font-size: 16px; } }

.user-list__actions--action-description {
  font-size: 14px;
  color: #646a75; }

.user-list__actions--remove-row {
  padding: 12px 0 15px;
  border: none; }
  .user-list__actions--remove-row:hover {
    background-color: rgba(255, 90, 20, 0.05); }

.user-list__actions--remove {
  font-family: "Brandon Text", sans-serif;
  font-weight: 700;
  font-size: 15px;
  color: #FF5A14; }
  @media screen and (min-width: 720px) {
    .user-list__actions--remove {
      font-size: 16px; } }

.user-list__info-icon {
  font-family: "Brandon Text", sans-serif;
  font-weight: 700;
  color: rgba(100, 106, 117, 0.8);
  display: inline;
  margin-left: 6px;
  position: relative;
  cursor: default; }

.user-list__role-description {
  font-family: "Brandon Text", sans-serif;
  font-weight: 400;
  font-size: 14px;
  padding: 7.8px 3px;
  text-align: center;
  width: 198px;
  color: #2d3644; }

.user-list__remove-modal--name {
  font-size: 19px;
  line-height: 1.3;
  font-family: "Brandon Text", sans-serif;
  font-weight: 700; }

.user-list__remove-modal--email {
  font-size: 15px;
  color: rgba(0, 0, 0, 0.65); }
  @media screen and (min-width: 720px) {
    .user-list__remove-modal--email {
      font-size: 16px; } }
