@charset "UTF-8";
.benchmarking {
  color: #2d3644;
  background-color: #fff;
  padding: 24px;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  overflow-y: scroll; }
  .benchmarking__title {
    font-size: 15px;
    margin-bottom: 6px; }
    @media screen and (min-width: 720px) {
      .benchmarking__title {
        font-size: 16px; } }
  .benchmarking__selections {
    /* flex: auto; */ }
  .benchmarking .button {
    margin-bottom: 0;
    margin-top: 12px;
    border-width: 2px; }
    .benchmarking .button––warning {
      border-color: rgba(255, 90, 20, 0.5); }
    .benchmarking .button:active, .benchmarking .button:hover {
      outline: none; }
  .benchmarking__selection {
    position: relative;
    -webkit-transition-duration: 250ms;
            transition-duration: 250ms;
    border-radius: 8px;
    border: 3px solid rgba(128, 128, 128, 0.25);
    padding: 12px;
    margin-bottom: 12px; }
  .benchmarking__selection-title-area {
    padding: 6px;
    padding-bottom: 18px; }
  .benchmarking__selection-title {
    font-size: 15px;
    margin-bottom: 3px; }
    @media screen and (min-width: 720px) {
      .benchmarking__selection-title {
        font-size: 16px; } }
  .benchmarking__selection-responses {
    font-family: "Brandon Text", sans-serif;
    font-weight: 700;
    font-size: 14px;
    margin: 0;
    color: #646a75; }
  .benchmarking__add-filter {
    font-size: 15px;
    color: #2084d8;
    background-color: #F0F4F7;
    padding: 10px 18px;
    border-radius: 20px;
    margin-bottom: 4px;
    display: block;
    width: 100%;
    text-align: left;
    -webkit-animation: pop-down 600ms linear;
            animation: pop-down 600ms linear;
    -webkit-transition: background-color 100ms, color 100ms;
    transition: background-color 100ms, color 100ms; }
    @media screen and (min-width: 720px) {
      .benchmarking__add-filter {
        font-size: 16px; } }
    .benchmarking__add-filter:hover, .benchmarking__add-filter:active {
      color: #fff;
      background-color: #2084d8; }
  .benchmarking__filter-panel {
    position: fixed;
    background-color: #FFF;
    z-index: 10;
    width: 336px;
    left: -12px;
    -webkit-box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.15);
            box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.15);
    padding: 12px;
    padding-top: 16px;
    border-radius: 8px; }
    .benchmarking__filter-panel .filter-panel__add {
      display: none; }
  .benchmarking .button {
    margin-bottom: 0;
    margin-top: 12px; }
  @media (min-width: 720px) {
    .benchmarking__mobile-apply {
      display: none; } }
