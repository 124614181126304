.task-list-new__item {
  /*@include animation-delay(20, 30ms);*/
  -webkit-animation: none;
          animation: none;
  padding: 24px;
  border-bottom: 4px solid #f5f7f9;
  background-color: rgba(255, 255, 255, 0.8); }
  .task-list-new__item:nth-child(even) {
    background-color: rgba(255, 255, 255, 0.3); }
  .task-list-new__item--header {
    display: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
    .task-list-new__item--header .task-list__section {
      padding-top: 18px;
      padding-bottom: 15px;
      padding-left: 12px;
      padding-right: 12px; }
  @media (min-width: 720px) {
    .task-list-new__item {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      padding: 0; } }

.task-list-new__title {
  font-size: 19px;
  line-height: 1.3;
  cursor: pointer;
  display: inline-block;
  margin: 0;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
  width: 100%;
  outline: none;
  -webkit-transition: color 200ms ease-in;
  transition: color 200ms ease-in; }
  .task-list-new__title:hover {
    color: #15558a; }
  .task-list-new__title--active {
    color: #2084d8; }

.task-list-new__titletext {
  float: left; }

.task-list-new__arrow {
  font-size: 19px;
  line-height: 1.3;
  cursor: pointer;
  position: relative;
  margin-top: -2px;
  padding: 3px 12px;
  border-radius: 24px;
  -webkit-transition: background-color 500ms ease-in;
  transition: background-color 500ms ease-in; }
  .task-list-new__arrow:hover {
    color: #15558a;
    background: #f5f7f9; }

.task-list-new__icon {
  font-size: 14px;
  position: relative;
  width: 24px;
  height: 24px;
  line-height: 22.2px;
  text-align: center;
  float: left;
  background: white;
  border-radius: 12px; }
  .task-list-new__icon--border {
    margin-left: 6px;
    border: 1px solid;
    border-color: #c6cecd; }

.task-list-new__category {
  display: block;
  font-size: 15px; }
  @media screen and (min-width: 720px) {
    .task-list-new__category {
      font-size: 16px; } }

.task-list-new__link {
  display: inline-block; }
  @media (min-width: 720px) {
    .task-list-new__link {
      margin-bottom: 3px; } }

@media (min-width: 720px) {
  .task-list-new .number__value {
    margin-bottom: 3px; } }

.task-list-new__category {
  color: #646a75; }

.task-list-new__section {
  position: relative; }
  @media (min-width: 720px) {
    .task-list-new__section {
      padding: 24px;
      padding-left: 20px;
      padding-right: 20px;
      border-right: 4px solid #f5f7f9; } }
  .task-list-new__section--task {
    /*padding-bottom: $spacing--small;*/
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
    @media (min-width: 720px) {
      .task-list-new__section--task {
        -webkit-box-flex: 1;
            -ms-flex: 1 1 30%;
                flex: 1 1 30%;
        max-width: 504px;
        border-left: 4px solid #f5f7f9; } }
  .task-list-new__section--header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row; }
  .task-list-new__section--demand {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-top: 12px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
    @media (min-width: 720px) {
      .task-list-new__section--demand {
        margin-top: 0;
        -webkit-box-flex: 1;
            -ms-flex: 1 1 20%;
                flex: 1 1 20%;
        max-width: 192px;
        min-width: 180px; } }
  .task-list-new__section--completion {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    @media (min-width: 720px) {
      .task-list-new__section--completion {
        -webkit-box-flex: 1;
            -ms-flex: 1 1 40%;
                flex: 1 1 40%;
        max-width: 504px; } }
  .task-list-new__section--time {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-top: 12px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
    @media (min-width: 720px) {
      .task-list-new__section--time {
        margin-top: 0;
        -webkit-box-flex: 1;
            -ms-flex: 1 1 10%;
                flex: 1 1 10%;
        max-width: 192px;
        min-width: 180px; } }

.task-list-new__details--bars {
  position: relative;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  margin-top: -7px; }

.task-list-new__details--demand {
  position: relative; }
  .task-list-new__details--demand .delta {
    margin-bottom: 12px; }
    @media (min-width: 720px) {
      .task-list-new__details--demand .delta {
        margin-bottom: 0; } }
  @media (min-width: 720px) {
    .task-list-new__details--demand .number__description {
      display: none; } }

.task-list-new__details--bars:hover:after + .task-list-new__details--benchmarking-summary {
  background-color: red; }

.task-list-new__details--delta {
  padding-left: 21px;
  width: 90px; }

.task-list-new__sort {
  -webkit-backface-visibility: hidden;
  -webkit-transform: scale(1);
  -webkit-transform: translate3D(0, 0, 0); }
  .task-list-new__sort--dropdown {
    position: absolute;
    z-index: 10;
    background: #fff;
    -webkit-box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.15);
            box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    top: 54px;
    left: 10px;
    width: 200px;
    line-height: 24px;
    padding: 4px; }
  .task-list-new__sort--text {
    margin-left: 9px; }
  .task-list-new__sort.pop-enter {
    opacity: 0; }
  .task-list-new__sort.pop-enter.pop-enter-active {
    opacity: 1;
    -webkit-animation: pop-down 600ms linear both;
            animation: pop-down 600ms linear both; }
  .task-list-new__sort.pop-exit {
    opacity: 1; }
  .task-list-new__sort.pop-exit.pop-exit-active {
    opacity: 0;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms; }

.task-list-new__sort-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #323C4C;
  padding: 6px;
  font-size: 15px;
  font-family: "Brandon Text", sans-serif;
  font-weight: 400;
  color: #2084d8;
  border-radius: 3px;
  -webkit-transition: background-color 150ms;
  transition: background-color 150ms; }
  @media screen and (min-width: 720px) {
    .task-list-new__sort-row {
      font-size: 16px; } }
  .task-list-new__sort-row:hover {
    background-color: rgba(32, 132, 216, 0.15); }
  .task-list-new__sort-row--selected, .task-list-new__sort-row--selected:hover {
    color: #fff;
    background-color: #2084d8; }
  .task-list-new__sort-row--active {
    color: #2084d8; }
    .task-list-new__sort-row--active:hover {
      background-color: rgba(32, 132, 216, 0.15); }
  .task-list-new__sort-row--plain:hover {
    background-color: rgba(32, 132, 216, 0.15); }
