.when-rules {
  font-family: "Brandon Text", sans-serif;
  font-weight: 400; }
  .when-rules__container {
    margin: 24px 0;
    padding: 24px 0;
    border: 1px solid #e2e5e8;
    border-left: 0;
    border-right: 0;
    position: relative; }
    @media (min-width: 960px) {
      .when-rules__container {
        border-top: 0;
        padding-top: 0;
        margin: 0;
        padding-bottom: 24px;
        margin-bottom: 30px; } }
  .when-rules__headers {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin-bottom: 6px; }
  .when-rules__rule {
    margin-top: 6px;
    background: #F0F4F7;
    border-radius: 3px;
    padding: 24px; }
    .when-rules__rule:first-child {
      margin-top: 18px; }
  .when-rules__question {
    width: 100%;
    margin-bottom: 4px; }
    .when-rules__question select {
      width: 100%; }
  .when-rules__details {
    width: 100%; }
    @media (min-width: 720px) {
      .when-rules__details {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex; } }
  .when-rules__modifier {
    margin-bottom: 4px; }
    @media (min-width: 720px) {
      .when-rules__modifier {
        margin-right: 4px;
        margin-bottom: 0; } }
  .when-rules__answer {
    width: 100%; }
    .when-rules__answer select {
      width: 100%; }
    .when-rules__answer input {
      width: 100%; }
  .when-rules__question select, .when-rules__question input, .when-rules__modifier select, .when-rules__modifier input {
    font-family: "Brandon Text", sans-serif;
    font-size: 15px;
    background-color: #fff;
    color: #2084d8;
    height: 44px;
    padding: 12px;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    border: none;
    border-radius: 3px; }
    @media screen and (min-width: 720px) {
      .when-rules__question select, .when-rules__question input, .when-rules__modifier select, .when-rules__modifier input {
        font-size: 16px; } }
    .when-rules__question select:focus, .when-rules__question input:focus, .when-rules__modifier select:focus, .when-rules__modifier input:focus {
      outline: 0; }
  .when-rules__input-container {
    position: relative; }
  .when-rules__modifier select, .when-rules__modifier input {
    width: 100%; }
    @media (min-width: 720px) {
      .when-rules__modifier select, .when-rules__modifier input {
        width: 126px; } }
  .when-rules__input-container select, .when-rules__input-container input {
    color: #2d3644; }
  .when-rules__single-select {
    cursor: pointer; }
    .when-rules__single-select:-ms-expand {
      display: none; }
  .when-rules__icon {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    pointer-events: none;
    height: 44px;
    padding: 13px 18px 0 12px;
    border-radius: 3px;
    background-color: #fff;
    line-height: 1; }
  .when-rules__answer-select {
    font-family: "Brandon Text", sans-serif;
    font-size: 15px;
    border: none;
    width: 100%; }
    @media screen and (min-width: 720px) {
      .when-rules__answer-select {
        font-size: 16px; } }
    .when-rules__answer-select .Select-control {
      width: 100%;
      background: #fff;
      border-radius: 3px;
      position: relative; }
    .when-rules__answer-select.Select--single .Select-control {
      height: 44px; }
    .when-rules__answer-select.Select--multi .Select-control {
      min-height: 44px; }
    .when-rules__answer-select .Select-multi-value-wrapper {
      display: inline-block;
      padding: 12px;
      max-width: 83%; }
    .when-rules__answer-select.Select--multi .Select-multi-value-wrapper {
      padding: 9px 12px;
      padding-bottom: 3px; }
    .when-rules__answer-select .Select-value {
      float: left; }
    .when-rules__answer-select.Select--single .Select-value {
      display: inline-block;
      max-width: 160px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
    .when-rules__answer-select.Select--multi .Select-value {
      background: rgba(32, 132, 216, 0.1);
      border: 1px solid rgba(32, 132, 216, 0.4);
      border-radius: 3px;
      font-size: 14px;
      color: #2084d8;
      margin: 0 3px 3px 0;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      max-width: 200px;
      -webkit-box-sizing: border-box;
              box-sizing: border-box; }
      .when-rules__answer-select.Select--multi .Select-value .Select-value-icon {
        border-right: 1px solid rgba(32, 132, 216, 0.4);
        padding: 2px 4px;
        line-height: 1;
        font-size: 17px; }
      .when-rules__answer-select.Select--multi .Select-value .Select-value-label {
        padding: 2px 4px;
        display: inline-block;
        max-width: 140px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; }
    .when-rules__answer-select .Select-placeholder {
      float: left;
      padding: 3px;
      color: #a8abad; }
    .when-rules__answer-select .Select-aria-only {
      display: none; }
    .when-rules__answer-select .Select-input {
      float: left; }
    .when-rules__answer-select .Select-input input {
      -webkit-appearance: none;
         -moz-appearance: none;
              appearance: none;
      border: 0;
      font-size: 15px; }
      @media screen and (min-width: 720px) {
        .when-rules__answer-select .Select-input input {
          font-size: 16px; } }
      .when-rules__answer-select .Select-input input:focus {
        outline: 0; }
    .when-rules__answer-select.Select--multi .Select-input input {
      padding: 2px 0; }
    .when-rules__answer-select .Select-menu-outer {
      font-size: 14px;
      border-radius: 3px;
      background-color: #fff;
      -webkit-box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.1);
              box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.1);
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      margin-top: -1px;
      max-height: 200px;
      position: absolute;
      z-index: 40;
      top: 100%;
      width: 100%;
      -webkit-overflow-scrolling: touch; }
    .when-rules__answer-select .Select-menu {
      max-height: 200px;
      overflow-y: auto;
      padding: 4px; }
    .when-rules__answer-select .Select-option {
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      background-color: #fff;
      border-radius: 1.5px;
      color: #2d3644;
      cursor: pointer;
      display: block;
      padding: 6px 12px;
      padding-right: 20px; }
    .when-rules__answer-select .Select-option.is-focused {
      background-color: #2084d8;
      color: #fff; }
    .when-rules__answer-select .Select-option.is-selected {
      background-color: rgba(32, 132, 216, 0.1);
      color: #2084d8; }
    .when-rules__answer-select .Select-option.is-disabled {
      color: #cccccc;
      cursor: default; }
  .when-rules__custom {
    border: none;
    border-radius: 3px;
    min-height: 44px;
    padding: 12px;
    font-size: 14px;
    font-family: monospace; }
    .when-rules__custom:focus {
      outline: none; }
  .when-rules__remove {
    font-size: 15px;
    margin-top: 12px;
    color: #f25613;
    cursor: pointer;
    display: inline-block; }
    @media screen and (min-width: 720px) {
      .when-rules__remove {
        font-size: 16px; } }
  .when-rules__add {
    font-family: "Brandon Text", sans-serif;
    font-weight: 700;
    margin-top: 18px;
    color: #2084d8; }
    .when-rules__add:hover {
      color: #15558a; }
    .when-rules__add:focus {
      outline: 0; }
