.invite-form {
  font-family: "Brandon Text", sans-serif;
  font-weight: 400; }
  .invite-form__input-container {
    font-size: 15px;
    margin-bottom: 6px; }
    @media screen and (min-width: 720px) {
      .invite-form__input-container {
        font-size: 16px; } }
    .invite-form__input-container input {
      font-size: 15px;
      border-radius: 4.8px;
      border: solid 2px rgba(50, 60, 76, 0.15);
      margin-top: 3px; }
      @media screen and (min-width: 720px) {
        .invite-form__input-container input {
          font-size: 16px; } }
    .invite-form__input-container--error input,
    .invite-form__input-container--error input:focus {
      border-color: #FF5A14; }
    .invite-form__input-container--warning input,
    .invite-form__input-container--warning input:focus {
      border-color: #7473D4; }
  .invite-form__row {
    position: relative;
    margin-bottom: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid rgba(50, 60, 76, 0.15); }
    @media (min-width: 720px) {
      .invite-form__row {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        margin-bottom: 12px;
        padding-bottom: 0;
        border-bottom: none; } }
  @media (min-width: 720px) {
    .invite-form__section:nth-child(1) {
      width: 441px;
      margin-right: 12px; }
    .invite-form__section:nth-child(2) {
      margin-right: 12px; } }
  @media (min-width: 720px) {
    .invite-form__section--has-errors {
      margin-bottom: 6px; } }
  .invite-form__error, .invite-form__warning {
    width: 100%;
    margin-top: 6px;
    font-size: 14px;
    font-family: "Brandon Text", sans-serif;
    font-weight: 700;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    margin-bottom: 12px; }
    @media (min-width: 720px) {
      .invite-form__error, .invite-form__warning {
        margin-bottom: 0; } }
  .invite-form__error {
    color: #FF5A14; }
  .invite-form__warning {
    color: #6864D7; }
  .invite-form__error-description, .invite-form__warning-description {
    color: #646a75;
    margin-left: .3em;
    font-weight: normal; }
  .invite-form__remove-invitation {
    cursor: pointer;
    color: rgba(100, 106, 117, 0.8);
    width: 24px;
    text-align: center;
    position: absolute;
    top: 1.85em;
    right: -24px; }
    @media (min-width: 720px) {
      .invite-form__remove-invitation {
        text-align: right; } }
  .invite-form__add {
    margin-top: 9px; }
    .invite-form__add .button {
      font-family: "Brandon Text", sans-serif;
      font-weight: 400;
      font-size: 19px;
      line-height: 1.3;
      outline: none; }
      .invite-form__add .button:nth-child(1) {
        margin-right: 6px; }
      .invite-form__add .button:nth-child(2) {
        margin-left: 6px; }
  .invite-form__roles {
    margin-top: 30px;
    position: relative; }
    .invite-form__roles label {
      font-size: 15px; }
      @media screen and (min-width: 720px) {
        .invite-form__roles label {
          font-size: 16px; } }
  .invite-form__role-button {
    width: 204px;
    margin-top: 6px; }
    .invite-form__role-button .button {
      text-transform: none;
      outline: none;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between; }
  .invite-form__role-button-text {
    font-family: "Brandon Text", sans-serif;
    font-weight: 700;
    text-transform: none;
    text-align: left; }
  .invite-form__confirmation {
    margin-top: 60px;
    float: right; }
    .invite-form__confirmation .button {
      outline: none; }
  .invite-form__actions--arrow {
    float: right;
    margin-top: -1px; }
  .invite-form__actions--dropdown {
    position: absolute;
    z-index: 10;
    background: #fff;
    -webkit-box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.15);
            box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    top: 78px;
    width: 264px; }
  .invite-form__actions--row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    border-bottom: solid 1px rgba(128, 128, 128, 0.25);
    cursor: pointer;
    -webkit-transition: background-color 200ms;
    transition: background-color 200ms;
    padding: 9.708px 0 10.8px; }
    .invite-form__actions--row:hover {
      background-color: rgba(32, 132, 216, 0.05); }
  .invite-form__actions--tick-box {
    width: 19px;
    margin: 0 12px;
    margin-top: 4px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
  .invite-form__actions--action-name {
    font-family: "Brandon Text", sans-serif;
    font-weight: 700;
    font-size: 15px;
    color: #2d3644; }
    @media screen and (min-width: 720px) {
      .invite-form__actions--action-name {
        font-size: 16px; } }
  .invite-form__actions--action-description {
    font-size: 14px;
    color: #646a75; }
  .invite-form__surveys-selection {
    margin-top: 60px; }
    .invite-form__surveys-selection--list {
      margin-top: 24px; }
      .invite-form__surveys-selection--list li {
        border-top: 1px solid #D5D8DC;
        padding: 12px 0; }
        @media (min-width: 720px) {
          .invite-form__surveys-selection--list li {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: justify;
                -ms-flex-pack: justify;
                    justify-content: space-between;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center; } }
        .invite-form__surveys-selection--list li p {
          margin-bottom: 0px;
          line-height: 45px;
          vertical-align: middle; }
      .invite-form__surveys-selection--list li:last-child {
        border-bottom: 1px solid #D5D8DC; }
    .invite-form__surveys-selection--access li {
      display: inline;
      border: none;
      padding: 10px 8px;
      border-top: 1px solid grey;
      border-bottom: 1px solid grey;
      border-right: 1px solid grey;
      cursor: pointer;
      background-color: #e5e5e5; }
    .invite-form__surveys-selection--access li:last-child {
      border-top-right-radius: 25px;
      border-bottom-right-radius: 25px; }
    .invite-form__surveys-selection--access li:first-child {
      border-top-left-radius: 25px;
      border-bottom-left-radius: 25px;
      border-left: 1px solid grey; }
    .invite-form__surveys-selection--active-no li:first-child {
      background-color: #c10729;
      color: white; }
    .invite-form__surveys-selection--active-read li:nth-child(2) {
      background-color: #2b78ff;
      color: white; }
    .invite-form__surveys-selection--active-write li:nth-child(3) {
      background-color: #41bf43;
      color: white; }
    .invite-form__surveys-selection--active-admin li:last-child {
      background-color: #454745;
      color: white; }
