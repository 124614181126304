.organisation-settings__go-back {
  margin-bottom: 24px;
  color: #646a75;
  font-weight: normal;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .organisation-settings__go-back .icon {
    margin-top: 4px; }
  .organisation-settings__go-back .icon__fill {
    fill: #646a75; }
  .organisation-settings__go-back:hover {
    text-decoration: none; }
    .organisation-settings__go-back:hover .icon__fill {
      fill: #2d3644; }
    .organisation-settings__go-back:hover span {
      color: #2d3644; }

.organisation-settings__go-back-text {
  margin-left: .4em;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: inline-block;
  max-width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.organisation-settings__sidenav li {
  margin-bottom: 6px; }

.organisation-settings__sidenav a {
  color: #646a75; }

.organisation-settings__sidenav a:hover,
.organisation-settings__sidenav a:focus {
  text-decoration: none; }

.organisation-settings__sidenav a.active {
  color: #2084d8; }
