.channel-filter__items {
  font-size: 15px;
  z-index: 1;
  position: fixed;
  right: 20px;
  border-radius: 6px;
  background-color: white;
  width: 320px;
  max-height: 500px;
  overflow-y: scroll;
  padding: 4px;
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 5px 0px rgba(0, 0, 0, 0.2);
          box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 5px 0px rgba(0, 0, 0, 0.2); }
  @media screen and (min-width: 720px) {
    .channel-filter__items {
      font-size: 16px; } }

.channel-filter__question {
  padding: 10px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-family: "Brandon Text", sans-serif;
  font-weight: 700;
  color: #646a75; }

.channel-filter__item {
  padding: 10px 12px;
  padding-right: 50px;
  border-radius: 3px;
  background: #FFF;
  position: relative; }
  .channel-filter__item:hover {
    background: #DDEBFE;
    color: #2084d8; }
  .channel-filter__item--selected, .channel-filter__item--selected:hover {
    background: #2084d8;
    color: white; }

.channel-filter__emojific {
  width: 1.5em;
  margin: 0 3px;
  display: inline-block; }

.channel-filter__tick {
  position: absolute;
  top: 50%;
  right: 16px;
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px); }
