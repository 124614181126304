.period-filter {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .period-filter__chart-container {
    width: 100%;
    overflow-x: scroll !important;
    direction: rtl;
    white-space: nowrap;
    margin-bottom: 18px;
    /* Track */
    /* Handle */ }
    .period-filter__chart-container::-webkit-scrollbar {
      height: 6px; }
    .period-filter__chart-container::-webkit-scrollbar-track {
      background: #EFF1F3;
      border-radius: 3px; }
    .period-filter__chart-container::-webkit-scrollbar-thumb {
      background-color: #BDC5CF;
      border-radius: 3px; }
      .period-filter__chart-container::-webkit-scrollbar-thumb:hover {
        background: #2084d8; }
    .period-filter__chart-container::-webkit-scrollbar-thumb:window-inactive {
      background-color: rgba(189, 197, 207, 0.6); }
  .period-filter__items {
    position: fixed;
    z-index: 10;
    border-radius: 6px;
    background-color: #fff;
    padding: 20px;
    width: 94%;
    right: 3%;
    -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 5px 0px rgba(0, 0, 0, 0.2);
            box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 5px 0px rgba(0, 0, 0, 0.2); }
    @media (min-width: 720px) {
      .period-filter__items {
        right: 20px;
        width: 480px; } }
  .period-filter__title {
    font-size: 14px;
    font-family: "Brandon Text", sans-serif;
    font-weight: 700;
    margin-bottom: 3px; }
  .period-filter__sub-text {
    font-size: 14px;
    color: #646a75;
    margin-bottom: 6px; }
  .period-filter__range-controls {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    font-size: 14px;
    font-family: "Brandon Text", sans-serif;
    font-weight: 700; }
  .period-filter__range-label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -ms-flex-line-pack: stretch;
        align-content: stretch;
    margin-bottom: 6px; }
  .period-filter__range-label span {
    width: 54px;
    color: #646a75; }
  .period-filter__range-inputs input {
    font-size: 14px !important;
    font-family: "Brandon Text", sans-serif;
    font-weight: 700;
    border-width: 1px !important;
    padding: 4px 10px !important;
    width: 90px !important; }
    @media (min-width: 720px) {
      .period-filter__range-inputs input {
        width: 120px !important; } }
  .period-filter__daterange-picker {
    position: fixed;
    margin-top: -15px;
    width: 300px; }
  .period-filter__range-actions {
    width: 50%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
            flex-flow: row wrap;
    -ms-flex-pack: distribute;
        justify-content: space-around; }
  .period-filter__range-button {
    font-family: "Brandon Text", sans-serif;
    font-weight: 700;
    color: #2d3644;
    background-color: #fff;
    border-radius: 5px;
    padding: 2px 8px;
    width: 50%;
    text-align: left; }
    .period-filter__range-button:focus {
      outline: none;
      background-color: #2084d8;
      color: #FFF; }
    .period-filter__range-button--destructive, .period-filter__range-button--destructive:focus {
      color: #FF5A14;
      background-color: transparent; }
    .period-filter__range-button--active {
      outline: none;
      background-color: #2084d8;
      color: #FFF; }

.recharts-cartesian-axis-ticks {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.recharts-reference-line-label {
  cursor: ew-resize; }

.recharts-responsive-container {
  overflow: hidden;
  direction: ltr; }

.recharts-layer.recharts-brush rect:first-child {
  stroke: transparent; }
