.setup-form {
  position: relative; }
  .setup-form__row {
    position: relative;
    margin-bottom: 24px; }
    @media (min-width: 720px) {
      .setup-form__row {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        margin-bottom: 12px;
        padding-bottom: 0;
        border-bottom: none; } }
  .setup-form__wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
  .setup-form__main {
    width: 100%; }
  .setup-form__item {
    border: 4px solid #e6eaed;
    border-radius: 8px;
    background-color: #fff;
    max-width: 980px;
    padding: 24px;
    margin: 6px; }
    @media (min-width: 960px) {
      .setup-form__item {
        margin: 60px auto;
        padding: 24px; } }
  .setup-form__input {
    margin-bottom: 24px; }
    .setup-form__input input[type='text'] {
      border-color: transparent; }
    .setup-form__input--spaceless {
      margin: 0; }
  .setup-form__header {
    margin-bottom: 36px; }
  .setup-form__title {
    text-transform: uppercase;
    letter-spacing: 0.17em;
    font-family: "Brandon Text", sans-serif;
    font-weight: 700;
    font-size: 26px;
    line-height: 1.1;
    text-align: center;
    font-size: 15px;
    margin: 0;
    margin-bottom: 12px;
    text-align: left; }
    @media screen and (min-width: 720px) {
      .setup-form__title {
        font-size: 16px; } }
  @media (min-width: 960px) {
    .setup-form__body {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: space-evenly;
          -ms-flex-pack: space-evenly;
              justify-content: space-evenly; } }
  @media (min-width: 960px) {
    .setup-form__section {
      padding: 24px; } }
  @media (min-width: 960px) {
    .setup-form__section {
      -webkit-box-flex: 1;
          -ms-flex: 1 1 50%;
              flex: 1 1 50%;
      max-width: 80%; }
      .setup-form__section--wide {
        max-width: 100%; } }
  @media (min-width: 960px) and (min-width: 960px) {
    .setup-form__section {
      padding-right: 24px; } }
  .setup-form__section--hide {
    display: none; }
  .setup-form__section--main {
    border-radius: 3px; }
  @media (min-width: 960px) {
    .setup-form__section--right {
      padding-left: 24px; } }
  .setup-form__option {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
    .setup-form__option-drag {
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      -o-user-select: none;
      user-select: none;
      -webkit-user-drag: none;
      -moz-user-drag: none;
      user-drag: none;
      cursor: move;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      padding: 0 12px;
      padding-top: 14px;
      background-color: #fff; }
      .setup-form__option-drag .icon__fill {
        fill: #cacdcf; }
      .setup-form__option-drag:hover, .setup-form__option-drag:focus {
        outline: 0; }
      .setup-form__option-drag:hover .icon__fill {
        fill: #2084d8; }
    .setup-form__option-remove {
      border-radius: 0;
      padding-right: 6px; }
      .setup-form__option-remove .icon__fill {
        fill: #cacdcf; }
      .setup-form__option-remove:hover .icon__fill {
        fill: #FF5A14; }
    .setup-form__option--dragged {
      -webkit-box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.2);
              box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.2); }
      .setup-form__option--dragged .setup-form__option-drag,
      .setup-form__option--dragged .setup-form__option-remove,
      .setup-form__option--dragged input[type='text'] {
        border-color: transparent; }
  .setup-form__group--options {
    border-radius: 3px; }
  .setup-form__group-title {
    text-transform: uppercase;
    letter-spacing: 0.17em;
    font-size: 12px; }
  .setup-form__remove-container {
    border-top: 1px solid #e2e5e8;
    text-align: center;
    padding: 24px 0; }
    @media (min-width: 960px) {
      .setup-form__remove-container {
        padding: 24px 0 0; } }
  .setup-form__remove {
    text-transform: uppercase;
    letter-spacing: 0.17em;
    font-family: "Brandon Text", sans-serif;
    font-weight: 700;
    font-size: 26px;
    line-height: 1.1;
    text-align: center;
    font-size: 15px;
    color: #f25613; }
    @media screen and (min-width: 720px) {
      .setup-form__remove {
        font-size: 16px; } }
    .setup-form__remove:hover, .setup-form__remove:focus {
      color: #15558a; }
  .setup-form__add {
    font-family: "Brandon Text", sans-serif;
    font-weight: 700;
    margin-top: 18px;
    color: #2084d8; }
    .setup-form__add:hover, .setup-form__add:focus {
      color: #15558a; }
  .setup-form__hidden {
    display: none; }
  .setup-form__avatar-upload {
    padding: 24px 0; }
    @media (min-width: 960px) {
      .setup-form__avatar-upload {
        padding-top: 52px; } }
  .setup-form__avatar-preview {
    position: relative;
    width: 100%;
    height: 100%; }
    .setup-form__avatar-preview img {
      width: 100%;
      height: 100%;
      background-size: cover;
      -webkit-transition: all .5s ease-in-out;
      transition: all .5s ease-in-out; }
    .setup-form__avatar-preview .button {
      outline: none;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      background-color: rgba(255, 255, 255, 0.5);
      border-radius: 0;
      color: #2d3644; }
      .setup-form__avatar-preview .button:hover {
        background-color: rgba(255, 255, 255, 0.6); }
      .setup-form__avatar-preview .button:active {
        background-color: rgba(255, 255, 255, 0.7); }
  .setup-form__upload-instructions {
    margin-top: 9px;
    margin-left: 6px;
    margin-right: 6px;
    font-size: 14px; }
  .setup-form__avatar-dropzone {
    border-radius: 9px;
    border: 1px dotted;
    margin-bottom: 12px;
    width: 150px;
    height: 150px;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    justify-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    text-align: center;
    overflow: hidden; }
    .setup-form__avatar-dropzone--active {
      border: 1px solid;
      border-color: #2084d8;
      background-color: rgba(50, 60, 76, 0.1); }
  .setup-form__under-title {
    margin-top: 54px; }
  .setup-form__drag-container--dragging {
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }
