.other-comments {
  margin-top: 6px; }
  .other-comments__item {
    -webkit-animation: fall-down 700ms linear both;
            animation: fall-down 700ms linear both;
    -webkit-animation-delay: 330ms;
            animation-delay: 330ms;
    margin-bottom: 24px; }
    .other-comments__item:nth-child(1) {
      -webkit-animation-delay: 30ms;
              animation-delay: 30ms; }
    .other-comments__item:nth-child(2) {
      -webkit-animation-delay: 60ms;
              animation-delay: 60ms; }
    .other-comments__item:nth-child(3) {
      -webkit-animation-delay: 90ms;
              animation-delay: 90ms; }
    .other-comments__item:nth-child(4) {
      -webkit-animation-delay: 120ms;
              animation-delay: 120ms; }
    .other-comments__item:nth-child(5) {
      -webkit-animation-delay: 150ms;
              animation-delay: 150ms; }
    .other-comments__item:nth-child(6) {
      -webkit-animation-delay: 180ms;
              animation-delay: 180ms; }
    .other-comments__item:nth-child(7) {
      -webkit-animation-delay: 210ms;
              animation-delay: 210ms; }
    .other-comments__item:nth-child(8) {
      -webkit-animation-delay: 240ms;
              animation-delay: 240ms; }
    .other-comments__item:nth-child(9) {
      -webkit-animation-delay: 270ms;
              animation-delay: 270ms; }
    .other-comments__item:nth-child(10) {
      -webkit-animation-delay: 300ms;
              animation-delay: 300ms; }
    .other-comments__item:last-child {
      margin-bottom: 6px; }
