/* DayPicker styles */
.DayPicker {
  display: inline-block; }

.DayPicker-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  padding: 6px 0;
  border-radius: 6px;
  background-color: #fff;
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 5px 0px rgba(0, 0, 0, 0.2);
          box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 5px 0px rgba(0, 0, 0, 0.2); }

.DayPicker-Month {
  display: table;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  margin: 0 6px; }

.DayPicker-NavBar {
  position: absolute;
  left: 0;
  right: 0;
  padding: 0 .5rem;
  top: 1rem; }

.DayPicker-NavButton {
  position: absolute;
  width: 13px;
  height: 13px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  cursor: pointer;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDggMTMiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTS41OSAxMS4wMmw0LjU4LTQuNzhMLjU5IDEuNDU4IDItLjAxbDYgNi4yNS02IDYuMjUiIGZpbGw9IiMyRDM2NDQiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==); }

.DayPicker-NavButton--prev {
  left: 1rem;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

.DayPicker-NavButton--next {
  right: 1rem; }

.DayPicker-NavButton--interactionDisabled {
  display: none; }

.DayPicker-Caption {
  display: table-caption;
  height: 32px;
  line-height: 32px;
  text-align: center;
  background: #ECEFF1;
  border-radius: 3px; }

.DayPicker-Weekdays {
  display: table-header-group; }

.DayPicker-WeekdaysRow {
  display: table-row;
  -webkit-box-shadow: 0 1px 0 0 #E5E5E5;
          box-shadow: 0 1px 0 0 #E5E5E5; }

.DayPicker-Weekday {
  display: table-cell;
  padding: .5rem;
  font-size: .875em;
  text-align: center;
  color: #8b9898; }

.DayPicker-Body {
  display: table-row-group; }

.DayPicker-Week {
  display: table-row; }

.DayPicker-Day {
  display: table-cell;
  padding: 6px 10px;
  text-align: center;
  cursor: pointer;
  vertical-align: middle; }

.DayPicker-WeekNumber {
  display: table-cell;
  padding: .5rem;
  text-align: right;
  vertical-align: middle;
  min-width: 1rem;
  font-size: 0.75em;
  cursor: pointer;
  color: #8b9898; }

.DayPicker--interactionDisabled .DayPicker-Day {
  cursor: default; }

.DayPicker-Footer {
  display: table-caption;
  caption-side: bottom;
  padding-top: .5rem; }

.DayPicker-TodayButton {
  border: none;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
  cursor: pointer;
  color: #4A90E2;
  font-size: 0.875em; }

/* Selected day */
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  color: #FFF;
  background-color: #2084d8;
  border-radius: 3px; }

/* Default modifiers */
.DayPicker-Day--today {
  color: #2084d8; }

.DayPicker-Day--disabled {
  color: #dce0e0;
  cursor: default;
  pointer-events: none; }

.DayPicker-Day--outside {
  cursor: default;
  color: #dce0e0; }

/* Example modifiers */
.DayPicker-Day--sunday {
  background-color: #f7f8f8; }

.DayPicker-Day--sunday:not(.DayPicker-Day--today) {
  color: #dce0e0; }

/* DayPickerInput */
/*
.DayPickerInput {
    display: inline-block;
}

.DayPickerInput-OverlayWrapper {
    position: relative;
}

.DayPickerInput-Overlay {
  left: 0;
  position: absolute;
  background: white;
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, .15);
          box-shadow: 0 2px 5px rgba(0, 0, 0, .15);
}
*/
