.invite-batch {
  font-family: "Brandon Text", sans-serif;
  font-weight: 400; }
  .invite-batch__text-area[type='text'], .invite-batch__text-area[type='email'] {
    padding: 12px 18px;
    border-radius: 4.8px;
    margin-top: 3px;
    border: solid 2px rgba(50, 60, 76, 0.15);
    line-height: 1.4; }
  .invite-batch__label {
    font-size: 15px; }
    @media screen and (min-width: 720px) {
      .invite-batch__label {
        font-size: 16px; } }
  .invite-batch__tip {
    margin-top: 12px;
    color: #646a75; }
  .invite-batch__error {
    font-size: 15px;
    margin-top: 6px;
    margin-bottom: 6px;
    color: #FF5A14; }
    @media screen and (min-width: 720px) {
      .invite-batch__error {
        font-size: 16px; } }
  .invite-batch__buttons {
    margin-top: 36px; }
    .invite-batch__buttons .button {
      outline: none; }
      .invite-batch__buttons .button:nth-child(1) {
        margin-right: 12px; }
