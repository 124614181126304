.invite-success {
  text-align: center; }
  .invite-success__title {
    max-width: 12em;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 36px; }
    @media (min-width: 720px) {
      .invite-success__title {
        margin-bottom: 60px; } }
    .invite-success__title--error {
      color: #FF5A14; }
  .invite-success__buttons {
    margin-top: 60px; }
    .invite-success__buttons .button {
      outline: none;
      margin-bottom: 12px; }
      .invite-success__buttons .button:nth-child(1) {
        margin-right: 12px; }
  .invite-success__error-container {
    margin-bottom: 24px;
    width: 100%;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto; }
  .invite-success__error-emails {
    width: 100%;
    text-align: left; }
    .invite-success__error-emails th, .invite-success__error-emails td {
      padding: 12px 0; }
    .invite-success__error-emails thead th {
      border-bottom: 2px solid #FF5A14;
      width: 50%; }
    .invite-success__error-emails tbody td {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
