.ReactModal__Body--open {
  overflow-y: hidden; }

.user-invite {
  font-family: "Brandon Text", sans-serif;
  font-weight: 400;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }
  .user-invite__wrapper {
    color: rgba(50, 60, 76, 0.8);
    padding: 24px;
    -webkit-box-pack: right;
        -ms-flex-pack: right;
            justify-content: right;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 1200px; }
    @media (min-width: 720px) {
      .user-invite__wrapper {
        padding-bottom: 30px; } }
    .user-invite__wrapper button {
      outline: none; }
  .user-invite__invite-modal--container {
    width: 100%;
    height: 100%;
    background-color: #FFF;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    position: relative;
    overflow-y: auto; }
    .user-invite__invite-modal--container:focus {
      outline: none; }
  .user-invite__invite-modal--content {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    padding: 24px;
    padding-top: 60px;
    padding-bottom: 84px;
    width: 100%;
    display: block;
    max-width: 780px; }
    @media (min-width: 720px) {
      .user-invite__invite-modal--content {
        padding-top: 10vw;
        padding-bottom: 12vw; } }
  .user-invite__invite-modal--title {
    text-align: center;
    margin-bottom: 36px; }
    @media (min-width: 720px) {
      .user-invite__invite-modal--title {
        margin-bottom: 60px; } }
  .user-invite__invite-modal--close-button {
    font-size: 26px;
    line-height: 1.1;
    position: absolute;
    top: 24px;
    right: 24px;
    color: rgba(100, 106, 117, 0.8);
    cursor: pointer; }
    @media (min-width: 720px) {
      .user-invite__invite-modal--close-button {
        position: fixed;
        top: 60px;
        right: 60px; } }
  .user-invite__invite-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 40; }
