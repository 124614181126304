.minimap {
  display: none;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  z-index: 10;
  overflow: auto;
  background-color: #fff;
  border-right: 4px solid #F0F4F7; }
  @media (min-width: 720px) {
    .minimap {
      display: initial;
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      height: 100vh;
      overflow: auto;
      width: 280px; } }
  .minimap__no-select {
    -webkit-user-select: none;
    -moz-user-select: -moz-none;
    -o-user-select: none;
    -ms-user-select: none;
        user-select: none; }
  .minimap__content {
    position: absolute;
    padding-top: 69px; }
  .minimap__section {
    padding: 24px 0;
    border-bottom: 4px solid #f5f7f9; }
  .minimap__title {
    font-size: 19px;
    line-height: 1.3; }
  .minimap__list {
    list-style: none;
    margin: 0;
    padding-right: 24px;
    padding-left: 24px; }
  .minimap__heading {
    text-transform: uppercase;
    letter-spacing: 0.17em;
    font-size: 14px;
    text-align: center;
    color: #646a75; }
