.tooltip__container {
  position: absolute;
  left: 50%;
  top: -9px;
  -webkit-transform: translate(-50%, -100%);
          transform: translate(-50%, -100%); }
  .tooltip__container--hide-mobile {
    display: none; }
    @media (min-width: 720px) {
      .tooltip__container--hide-mobile {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex; } }

.tooltip__contents {
  background: #fff;
  -webkit-box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.15);
          box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: 3px 10px 5px;
  position: relative; }
  .tooltip__contents:before, .tooltip__contents:after {
    content: '';
    position: absolute;
    left: 50%;
    top: 100%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%); }
  .tooltip__contents:before {
    border: 9px solid transparent;
    border-top: 6px solid rgba(0, 0, 0, 0.15); }
  .tooltip__contents:after {
    border: 6px solid transparent;
    border-top: 3px solid #fff;
    margin-bottom: 6px; }
