.code {
  margin-top: 60px; }
  .code__instructions {
    font-size: 26px;
    line-height: 1.1; }
  .code__code {
    font-size: 22px;
    line-height: 1.6;
    background-color: #303131;
    color: #fcfdfd;
    margin: 24px 0;
    padding: 24px;
    display: block;
    position: relative;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word; }
