.preset-filter__items {
  z-index: 1;
  -webkit-transform-origin: bottom;
          transform-origin: bottom;
  font-size: 15px;
  position: fixed;
  right: 20px;
  border-radius: 6px;
  background-color: white;
  width: 320px;
  padding: 4px;
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 5px 0px rgba(0, 0, 0, 0.2);
          box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 5px 0px rgba(0, 0, 0, 0.2); }
  @media screen and (min-width: 720px) {
    .preset-filter__items {
      font-size: 16px; } }

.preset-filter__item, .preset-filter__empty {
  padding: 10px;
  background: #fff; }

.preset-filter__item {
  border-radius: 3px; }
  .preset-filter__item:hover {
    background: #FFE378; }

.preset-filter__empty {
  color: #646a75; }

.preset-filter__emojific {
  width: 1.5em;
  margin: 0 3px;
  display: inline-block; }
