.overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 40; }
  .overlay__after-open {
    -webkit-animation: fade-in 300ms cubic-bezier(0, 0, 0.1, 1);
            animation: fade-in 300ms cubic-bezier(0, 0, 0.1, 1); }

.preset-modal__label {
  margin-bottom: 24px; }
  .preset-modal__label input[type='text'] {
    margin-top: 12px;
    margin-bottom: 12px; }

.preset-modal__content {
  width: 380px;
  padding: 30px;
  padding-top: 26px;
  position: fixed;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: #FFF;
  -webkit-box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.15);
          box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.15);
  border-radius: 8px; }
  .preset-modal__content:focus {
    outline: none; }
  @media (min-width: 720px) {
    .preset-modal__content {
      width: 480px; } }

.preset-modal__controls {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap; }
  .preset-modal__controls button {
    margin-right: 12px;
    margin-bottom: 0; }
