.popup-enter.popup-enter-active {
  -webkit-animation: pop-up 500ms linear;
          animation: pop-up 500ms linear; }

.popup-exit {
  opacity: 1; }

.popup-exit.popup-exit-active {
  opacity: 0;
  -webkit-transition: opacity 300ms;
  transition: opacity 300ms; }
