.demand-filter__items {
  -webkit-transform-origin: bottom;
          transform-origin: bottom;
  font-size: 15px;
  position: fixed;
  z-index: 10;
  right: 20px;
  border-radius: 6px;
  background-color: white;
  width: 320px;
  padding: 14px;
  padding-top: 12px;
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 5px 0px rgba(0, 0, 0, 0.2);
          box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 5px 0px rgba(0, 0, 0, 0.2); }
  @media screen and (min-width: 720px) {
    .demand-filter__items {
      font-size: 16px; } }

.demand-filter__emojific {
  width: 1.5em;
  margin-top: 11px;
  display: inline-block; }

.demand-filter__input-range {
  width: 260px;
  padding: 10px;
  padding-bottom: 24px;
  float: right;
  display: inline-block; }

.demand-filter__title {
  font-size: 14px;
  font-family: "Brandon Text", sans-serif;
  font-weight: 700;
  color: #646a75;
  margin-bottom: 3px; }

.input-range {
  height: 1rem;
  position: relative;
  width: 100%; }
  .input-range__slider {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    background: #fff;
    -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.5);
            box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.5);
    border-radius: 100%;
    cursor: pointer;
    display: block;
    height: 1rem;
    margin-left: -0.5rem;
    margin-top: -0.65rem;
    outline: none;
    position: absolute;
    top: 50%;
    -webkit-transition: -webkit-transform 0.3s ease-out, -webkit-box-shadow 0.3s ease-out;
    transition: -webkit-transform 0.3s ease-out, -webkit-box-shadow 0.3s ease-out;
    transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
    transition: transform 0.3s ease-out, box-shadow 0.3s ease-out, -webkit-transform 0.3s ease-out, -webkit-box-shadow 0.3s ease-out;
    width: 1rem; }
    .input-range__slider:active {
      -webkit-transform: scale(1.3);
              transform: scale(1.3); }
    .input-range__slider:focus {
      -webkit-box-shadow: 0 0 0 5px rgba(63, 81, 181, 0.2);
              box-shadow: 0 0 0 5px rgba(63, 81, 181, 0.2); }
  .input-range--disabled .input-range__slider {
    background: #9BA2AB;
    border: 1px solid #9BA2AB;
    -webkit-box-shadow: none;
            box-shadow: none;
    -webkit-transform: none;
            transform: none; }
  .input-range__slider-container {
    -webkit-transition: left 0.3s ease-out;
    transition: left 0.3s ease-out; }
  .input-range__label {
    color: #2084d8;
    font-family: "Brandon Text", sans-serif;
    font-weight: 700;
    font-size: 14px;
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    white-space: nowrap; }
    .input-range__label--min, .input-range__label--max {
      bottom: -1.4rem;
      position: absolute;
      display: none; }
    .input-range__label--min {
      left: 0; }
    .input-range__label--max {
      right: 0; }
    .input-range__label--value {
      position: absolute;
      bottom: -1.8rem; }
    .input-range__label-container {
      left: -50%;
      position: relative; }
    .input-range__label--max .input-range__label-container {
      left: 50%; }
  .input-range__track {
    background: #eeeeee;
    border-radius: 0.3rem;
    cursor: pointer;
    display: block;
    height: 0.3rem;
    position: relative;
    -webkit-transition: left 0.3s ease-out, width 0.3s ease-out;
    transition: left 0.3s ease-out, width 0.3s ease-out; }
    .input-range__track--background {
      left: 0;
      margin-top: -0.15rem;
      position: absolute;
      right: 0;
      top: 50%; }
    .input-range__track--active {
      background: #2084d8; }
  .input-range--disabled .input-range__track {
    background: #eee; }
