.survey-layout {
  position: relative; }
  .survey-layout__content {
    width: 100%; }
  @media (min-width: 1160px) {
    .survey-layout--showFilters .survey-layout__content {
      width: calc(100% - 360px);
      -webkit-transition: width 400ms linear;
      transition: width 400ms linear; } }
  .survey-layout__drawer {
    display: none;
    -webkit-animation: filter-in 400ms linear both;
            animation: filter-in 400ms linear both;
    width: 100%;
    position: fixed;
    right: 0;
    top: 48px;
    bottom: 0;
    z-index: 41;
    color: #fff;
    background-color: rgba(58, 59, 59, 0.95);
    -webkit-box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.1);
            box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.1); }
    @media (min-width: 720px) {
      .survey-layout__drawer {
        width: 360px; } }
    .survey-layout__drawer--beta {
      overflow-x: visible; }
    .survey-layout__drawer--normal {
      overflow-y: scroll; }
  .survey-layout--showFilters .survey-layout__drawer {
    display: block; }
  .survey-layout__toggle-button--backdrop {
    -webkit-animation: fade-in 300ms linear both;
            animation: fade-in 300ms linear both;
    display: none;
    position: fixed;
    z-index: 30;
    top: 48px;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    outline: 0;
    -webkit-transition: background 500ms linear;
    transition: background 500ms linear;
    color: transparent;
    background-color: #fff; }
    @media (min-width: 360px) {
      .survey-layout__toggle-button--backdrop {
        background-color: rgba(255, 255, 255, 0.5); } }
    .survey-layout__toggle-button--backdrop:hover, .survey-layout__toggle-button--backdrop:focus {
      background-color: rgba(255, 255, 255, 0.6); }
  .survey-layout--showFilters .survey-layout__drawer,
  .survey-layout--showFilters .survey-layout__toggle-button--backdrop {
    display: block; }

@-webkit-keyframes filter-in {
  0.00% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0.194, 1, 0, 0, 0, 0, 1, 0, 600, 0, 1, 1);
            transform: matrix3d(1, 0, 0, 0, 0.194, 1, 0, 0, 0, 0, 1, 0, 600, 0, 1, 1);
    opacity: 0; }
  8.33% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0.149, 1, 0, 0, 0, 0, 1, 0, 463.414, 0, 1, 1);
            transform: matrix3d(1, 0, 0, 0, 0.149, 1, 0, 0, 0, 0, 1, 0, 463.414, 0, 1, 1); }
  16.67% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0.112, 1, 0, 0, 0, 0, 1, 0, 349.31, 0, 1, 1);
            transform: matrix3d(1, 0, 0, 0, 0.112, 1, 0, 0, 0, 0, 1, 0, 349.31, 0, 1, 1); }
  25.00% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0.081, 1, 0, 0, 0, 0, 1, 0, 253.125, 0, 1, 1);
            transform: matrix3d(1, 0, 0, 0, 0.081, 1, 0, 0, 0, 0, 1, 0, 253.125, 0, 1, 1);
    opacity: 1; }
  33.33% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0.057, 1, 0, 0, 0, 0, 1, 0, 178.445, 0, 1, 1);
            transform: matrix3d(1, 0, 0, 0, 0.057, 1, 0, 0, 0, 0, 1, 0, 178.445, 0, 1, 1); }
  41.67% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0.038, 1, 0, 0, 0, 0, 1, 0, 120.121, 0, 1, 1);
            transform: matrix3d(1, 0, 0, 0, 0.038, 1, 0, 0, 0, 0, 1, 0, 120.121, 0, 1, 1); }
  50.00% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0.024, 1, 0, 0, 0, 0, 1, 0, 75, 0, 1, 1);
            transform: matrix3d(1, 0, 0, 0, 0.024, 1, 0, 0, 0, 0, 1, 0, 75, 0, 1, 1); }
  58.33% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0.014, 1, 0, 0, 0, 0, 1, 0, 43.664, 0, 1, 1);
            transform: matrix3d(1, 0, 0, 0, 0.014, 1, 0, 0, 0, 0, 1, 0, 43.664, 0, 1, 1); }
  66.67% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0.007, 1, 0, 0, 0, 0, 1, 0, 22.557, 0, 1, 1);
            transform: matrix3d(1, 0, 0, 0, 0.007, 1, 0, 0, 0, 0, 1, 0, 22.557, 0, 1, 1); }
  75.00% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0.003, 1, 0, 0, 0, 0, 1, 0, 9.375, 0, 1, 1);
            transform: matrix3d(1, 0, 0, 0, 0.003, 1, 0, 0, 0, 0, 1, 0, 9.375, 0, 1, 1); }
  83.33% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0.001, 1, 0, 0, 0, 0, 1, 0, 2.82, 0, 1, 1);
            transform: matrix3d(1, 0, 0, 0, 0.001, 1, 0, 0, 0, 0, 1, 0, 2.82, 0, 1, 1); }
  91.67% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0.368, 0, 1, 1);
            transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0.368, 0, 1, 1); }
  100.00% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 1, 1);
            transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 1, 1); } }

@keyframes filter-in {
  0.00% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0.194, 1, 0, 0, 0, 0, 1, 0, 600, 0, 1, 1);
            transform: matrix3d(1, 0, 0, 0, 0.194, 1, 0, 0, 0, 0, 1, 0, 600, 0, 1, 1);
    opacity: 0; }
  8.33% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0.149, 1, 0, 0, 0, 0, 1, 0, 463.414, 0, 1, 1);
            transform: matrix3d(1, 0, 0, 0, 0.149, 1, 0, 0, 0, 0, 1, 0, 463.414, 0, 1, 1); }
  16.67% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0.112, 1, 0, 0, 0, 0, 1, 0, 349.31, 0, 1, 1);
            transform: matrix3d(1, 0, 0, 0, 0.112, 1, 0, 0, 0, 0, 1, 0, 349.31, 0, 1, 1); }
  25.00% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0.081, 1, 0, 0, 0, 0, 1, 0, 253.125, 0, 1, 1);
            transform: matrix3d(1, 0, 0, 0, 0.081, 1, 0, 0, 0, 0, 1, 0, 253.125, 0, 1, 1);
    opacity: 1; }
  33.33% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0.057, 1, 0, 0, 0, 0, 1, 0, 178.445, 0, 1, 1);
            transform: matrix3d(1, 0, 0, 0, 0.057, 1, 0, 0, 0, 0, 1, 0, 178.445, 0, 1, 1); }
  41.67% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0.038, 1, 0, 0, 0, 0, 1, 0, 120.121, 0, 1, 1);
            transform: matrix3d(1, 0, 0, 0, 0.038, 1, 0, 0, 0, 0, 1, 0, 120.121, 0, 1, 1); }
  50.00% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0.024, 1, 0, 0, 0, 0, 1, 0, 75, 0, 1, 1);
            transform: matrix3d(1, 0, 0, 0, 0.024, 1, 0, 0, 0, 0, 1, 0, 75, 0, 1, 1); }
  58.33% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0.014, 1, 0, 0, 0, 0, 1, 0, 43.664, 0, 1, 1);
            transform: matrix3d(1, 0, 0, 0, 0.014, 1, 0, 0, 0, 0, 1, 0, 43.664, 0, 1, 1); }
  66.67% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0.007, 1, 0, 0, 0, 0, 1, 0, 22.557, 0, 1, 1);
            transform: matrix3d(1, 0, 0, 0, 0.007, 1, 0, 0, 0, 0, 1, 0, 22.557, 0, 1, 1); }
  75.00% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0.003, 1, 0, 0, 0, 0, 1, 0, 9.375, 0, 1, 1);
            transform: matrix3d(1, 0, 0, 0, 0.003, 1, 0, 0, 0, 0, 1, 0, 9.375, 0, 1, 1); }
  83.33% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0.001, 1, 0, 0, 0, 0, 1, 0, 2.82, 0, 1, 1);
            transform: matrix3d(1, 0, 0, 0, 0.001, 1, 0, 0, 0, 0, 1, 0, 2.82, 0, 1, 1); }
  91.67% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0.368, 0, 1, 1);
            transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0.368, 0, 1, 1); }
  100.00% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 1, 1);
            transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 1, 1); } }
