.why-instead {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 24px;
  background-color: rgba(255, 255, 255, 0.3);
  margin-bottom: 2px; }
  @media (min-width: 720px) {
    .why-instead {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row; } }
  .why-instead__section {
    -webkit-animation: fade-in 500ms linear both;
            animation: fade-in 500ms linear both;
    width: 100%;
    max-width: 100%;
    margin: 24px 0; }
    @media (min-width: 720px) {
      .why-instead__section {
        width: 360px;
        margin: 24px 60px; } }
  .why-instead__title {
    font-size: 26px;
    line-height: 1.1;
    margin-bottom: 12px; }
  .why-instead__responses {
    margin-bottom: 24px; }
  .why-instead__options {
    border-top: 4px solid rgba(50, 60, 76, 0.1);
    padding-top: 12px; }
  .why-instead__option {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 12px 0; }
  .why-instead__demand {
    padding-top: 3px;
    /* Optical alignment */ }
  .why-instead__sub-title, .why-instead__sub-description {
    margin: 0; }
  .why-instead__sub-title {
    font-family: "Brandon Text", sans-serif;
    font-weight: 700; }
    .why-instead__sub-title--toggle {
      text-decoration: underline; }
