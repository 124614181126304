.navigation {
  font-size: 15px;
  z-index: 31;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  width: 100%;
  -webkit-transition: width 400ms;
  transition: width 400ms;
  height: 64px; }
  @media screen and (min-width: 720px) {
    .navigation {
      font-size: 16px; } }
  @media (min-width: 720px) {
    .navigation {
      height: 68px; } }
  .navigation__toggle-button {
    font-family: "Brandon Text", sans-serif;
    font-weight: 700;
    margin-right: auto; }
    @media (min-width: 720px) {
      .navigation__toggle-button {
        display: none; } }
    .navigation__toggle-button .icon {
      margin-right: 6px; }
  .navigation__menu {
    display: none;
    position: fixed;
    z-index: 30;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 440px;
    height: 100vh;
    padding: 24px;
    background-color: #fff;
    -webkit-animation: menu-in 300ms cubic-bezier(0, 0, 0.1, 1) both;
            animation: menu-in 300ms cubic-bezier(0, 0, 0.1, 1) both; }
    @media (min-width: 720px) {
      .navigation__menu {
        background: none;
        position: static;
        width: auto;
        height: auto;
        padding: 0;
        display: inline;
        margin-right: auto;
        -webkit-animation: none;
                animation: none; } }
    .navigation__menu--expanded {
      display: block; }
  .navigation__backdrop {
    position: fixed;
    content: '';
    z-index: 20;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    outline: 0;
    color: transparent;
    background-color: rgba(0, 0, 0, 0.2);
    -webkit-transition: background 500ms linear;
    transition: background 500ms linear;
    -webkit-animation: fade-in 300ms linear both;
            animation: fade-in 300ms linear both; }
  .navigation__menu-top {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    margin-bottom: 24px; }
    @media (min-width: 720px) {
      .navigation__menu-top {
        display: none; } }
  .navigation__close-button {
    border-radius: 50%;
    height: 32px;
    width: 34px;
    margin-right: -12px; }
    .navigation__close-button .icon {
      top: -2px; }
    .navigation__close-button:hover, .navigation__close-button:focus {
      background-color: #f5f7f9; }
  .navigation__link {
    color: rgba(45, 54, 68, 0.9);
    width: 100%;
    display: block;
    padding: 12px 0;
    -webkit-animation: menu-item-in 300ms cubic-bezier(0, 0, 0.1, 1) both;
            animation: menu-item-in 300ms cubic-bezier(0, 0, 0.1, 1) both;
    -webkit-animation-delay: 550ms;
            animation-delay: 550ms; }
    .navigation__link:nth-child(1) {
      -webkit-animation-delay: 50ms;
              animation-delay: 50ms; }
    .navigation__link:nth-child(2) {
      -webkit-animation-delay: 100ms;
              animation-delay: 100ms; }
    .navigation__link:nth-child(3) {
      -webkit-animation-delay: 150ms;
              animation-delay: 150ms; }
    .navigation__link:nth-child(4) {
      -webkit-animation-delay: 200ms;
              animation-delay: 200ms; }
    .navigation__link:nth-child(5) {
      -webkit-animation-delay: 250ms;
              animation-delay: 250ms; }
    .navigation__link:nth-child(6) {
      -webkit-animation-delay: 300ms;
              animation-delay: 300ms; }
    .navigation__link:nth-child(7) {
      -webkit-animation-delay: 350ms;
              animation-delay: 350ms; }
    .navigation__link:nth-child(8) {
      -webkit-animation-delay: 400ms;
              animation-delay: 400ms; }
    .navigation__link:nth-child(9) {
      -webkit-animation-delay: 450ms;
              animation-delay: 450ms; }
    .navigation__link:nth-child(10) {
      -webkit-animation-delay: 500ms;
              animation-delay: 500ms; }
    @media (min-width: 720px) {
      .navigation__link {
        margin-right: 18px;
        padding: 0;
        width: auto;
        display: inline-block; } }
    .navigation__link--active {
      text-decoration: underline; }
    .navigation__link--back {
      padding: 0;
      margin: 0; }
      .navigation__link--back .icon {
        margin-right: 6px; }
    .navigation__link--setup {
      display: inline;
      margin-right: 18px;
      -webkit-animation: fade-in 300ms cubic-bezier(0, 0, 0.1, 1) both;
              animation: fade-in 300ms cubic-bezier(0, 0, 0.1, 1) both; }
      @media (min-width: 720px) {
        .navigation__link--setup {
          color: #fff;
          margin-right: 18px; }
          .navigation__link--setup:hover, .navigation__link--setup:focus {
            color: #fff; } }
  .navigation__menu-setup {
    margin-top: 6px; }
    @media (min-width: 720px) {
      .navigation__menu-setup {
        margin-top: 0;
        margin-left: auto;
        padding: 9px 12px;
        padding-right: 21px;
        padding-left: 21px;
        border-radius: 50px;
        background-color: rgba(50, 60, 76, 0.4); } }
  .navigation__filter {
    font-family: "Brandon Text", sans-serif;
    font-weight: 700;
    padding: 6px 12px;
    border-radius: 50px;
    color: rgba(45, 54, 68, 0.9);
    float: right;
    margin-top: -9px;
    -webkit-transition: border 100ms cubic-bezier(0, 0, 0.1, 1);
    transition: border 100ms cubic-bezier(0, 0, 0.1, 1); }
    .navigation__filter--close {
      position: absolute;
      right: 0;
      margin-top: 3px;
      opacity: 0.8; }
      .navigation__filter--close:hover {
        opacity: 1; }
    .navigation__filter--open {
      border: 2px solid rgba(45, 54, 68, 0.4);
      padding: 6px 24px; }
    .navigation__filter:hover, .navigation__filter:focus {
      border-color: rgba(45, 54, 68, 0.6);
      outline: 0; }
    @media (min-width: 720px) {
      .navigation__filter {
        float: right; } }
    .navigation__filter--active {
      color: #fff;
      border-color: #2084d8;
      background-color: #2084d8; }
      .navigation__filter--active:hover, .navigation__filter--active:focus {
        border-color: rgba(45, 54, 68, 0.3); }

@-webkit-keyframes menu-in {
  from {
    -webkit-transform: translate3d(-100%, 0, 0) skewX(-10deg);
            transform: translate3d(-100%, 0, 0) skewX(-10deg); }
  to {
    -webkit-transform: matrix3d(1, 1, 1);
            transform: matrix3d(1, 1, 1); } }

@keyframes menu-in {
  from {
    -webkit-transform: translate3d(-100%, 0, 0) skewX(-10deg);
            transform: translate3d(-100%, 0, 0) skewX(-10deg); }
  to {
    -webkit-transform: matrix3d(1, 1, 1);
            transform: matrix3d(1, 1, 1); } }

@-webkit-keyframes menu-item-in {
  0% {
    -webkit-transform: translateX(-100px);
            transform: translateX(-100px);
    opacity: 0; }
  60% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px);
    opacity: 1; } }

@keyframes menu-item-in {
  0% {
    -webkit-transform: translateX(-100px);
            transform: translateX(-100px);
    opacity: 0; }
  60% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px);
    opacity: 1; } }
