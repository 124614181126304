.error-message {
  font-family: "Brandon Text", sans-serif;
  font-weight: 700;
  position: relative;
  z-index: 10;
  padding: 24px;
  width: 100%;
  text-align: center;
  color: #fff;
  border-bottom: 4px solid #f5f7f9;
  -webkit-animation: pop-up 600ms linear both;
          animation: pop-up 600ms linear both; }
  .error-message a {
    color: #fff;
    text-decoration: underline; }
  .error-message--error {
    background-color: #FF5A14; }
  .error-message--warning {
    background-color: #FFD400; }
  .error-message--info {
    background-color: rgba(32, 132, 216, 0.4); }
  .error-message--right {
    font-size: 15px;
    position: absolute;
    right: -6px;
    bottom: 60px;
    width: auto;
    border: 0;
    border-radius: 50px;
    padding: 4px 12px; }
    @media screen and (min-width: 720px) {
      .error-message--right {
        font-size: 16px; } }
    .error-message--right:before {
      content: '';
      display: block;
      position: absolute;
      width: 12px;
      height: 12px;
      right: 18px;
      bottom: -5px;
      z-index: -1;
      background-color: inherit;
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg); }
  .error-message--top {
    font-size: 15px;
    position: absolute;
    right: -6px;
    bottom: 60px;
    width: 200px;
    border: 0;
    border-radius: 50px;
    padding: 4px 12px; }
    @media screen and (min-width: 720px) {
      .error-message--top {
        font-size: 16px; } }
    .error-message--top:before {
      content: '';
      display: block;
      position: absolute;
      width: 12px;
      height: 12px;
      right: 18px;
      bottom: -5px;
      z-index: -1;
      background-color: inherit;
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg); }
