.icon {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  top: -3px;
  /* TODO: Can this be a global rule? */
  -webkit-transition: -webkit-transform 300ms cubic-bezier(0, 0, 0.1, 1);
  transition: -webkit-transform 300ms cubic-bezier(0, 0, 0.1, 1);
  transition: transform 300ms cubic-bezier(0, 0, 0.1, 1);
  transition: transform 300ms cubic-bezier(0, 0, 0.1, 1), -webkit-transform 300ms cubic-bezier(0, 0, 0.1, 1); }
  .icon__line {
    fill: none;
    stroke: #fff;
    stroke-width: 3; }
  .icon__fill {
    fill: #fff; }
  .icon--right {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
  .icon--up {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg); }
  .icon--down {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg); }
  .icon--primary .icon__line {
    stroke: #2084d8; }
  .icon--primary .icon__fill {
    fill: #2084d8; }
  .icon--dark .icon__line {
    stroke: #323C4C; }
  .icon--dark .icon__fill {
    fill: #323C4C; }
  .icon--light .icon__line {
    stroke: #fff; }
  .icon--light .icon__fill {
    fill: #fff; }
  .icon--muted .icon__line {
    stroke: #646a75; }
  .icon--muted .icon__fill {
    fill: #646a75; }
  .icon--drag {
    cursor: move; }
