.filter-actions__item {
  font-size: 15px;
  color: #656AD3;
  background-color: #F2F3FF;
  margin-bottom: 6px;
  min-height: 40px;
  border-radius: 20px;
  padding: 10px 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 4px;
  position: relative;
  width: 100%; }
  @media screen and (min-width: 720px) {
    .filter-actions__item {
      font-size: 16px; } }
