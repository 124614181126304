.filter-item {
  width: 50%;
  margin-left: -3px;
  padding-left: 3px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  margin-bottom: 6px; }

.button-add-filter {
  background-color: #f6f7f9;
  -webkit-transition: background-color .1s;
  transition: background-color .1s;
  font-size: 14px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 6px 12px;
  border-radius: 20px;
  cursor: pointer; }
  .button-add-filter .emoji {
    width: 26px;
    float: left; }
  .button-add-filter.preset-filter, .button-add-filter.preset-filter[class*="disabled"]:hover {
    background-color: #FDF6E5; }
  .button-add-filter.preset-filter:hover {
    background-color: #f3ecdc; }
  .button-add-filter:hover {
    background-color: #ecedef; }
  .button-add-filter [class*="disabled"], .button-add-filter.preset-filter[class*="disabled"] {
    pointer-events: none;
    color: rgba(100, 106, 117, 0.6); }
    .button-add-filter [class*="disabled"]:hover, .button-add-filter.preset-filter[class*="disabled"]:hover {
      background-color: #f6f7f9; }
