.fade-enter {
  opacity: 0; }

.fade-enter.fade-enter-active {
  opacity: 1;
  -webkit-transition: opacity 300ms ease-in;
  transition: opacity 300ms ease-in; }

.fade-exit {
  opacity: 1; }

.fade-exit.fade-exit-active {
  opacity: 0;
  -webkit-transition: opacity 300ms ease-in;
  transition: opacity 300ms ease-in; }
