#intercom-container {
  z-index: 40 !important;
  display: visible; }
  body.hide-intercom #intercom-container {
    display: none; }

#us_report_button {
  -webkit-transform: translateZ(1px) rotate(-90deg);
          transform: translateZ(1px) rotate(-90deg); }
  @media (max-width: 400px) {
    #us_report_button {
      z-index: 30 !important; } }
