.url-filter__items {
  -webkit-transform-origin: bottom;
          transform-origin: bottom;
  z-index: 30;
  font-size: 15px;
  position: fixed;
  right: 20px;
  border-radius: 6px;
  background-color: white;
  width: 320px;
  padding: 4px;
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 5px 0px rgba(0, 0, 0, 0.2);
          box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 5px 0px rgba(0, 0, 0, 0.2); }
  @media screen and (min-width: 720px) {
    .url-filter__items {
      font-size: 16px; } }
  .url-filter__items > div {
    padding: 10px; }

.url-filter__icon {
  width: 10%;
  padding: 3px;
  float: left; }

.url-filter__input[type='text'] {
  font-size: 15px;
  background: #fff;
  width: 70%;
  padding: 0 10px;
  border: none;
  display: inline; }
  @media screen and (min-width: 720px) {
    .url-filter__input[type='text'] {
      font-size: 16px; } }

.url-filter__save {
  position: absolute;
  right: 13px;
  font-size: 14px;
  font-family: "Brandon Text", sans-serif;
  font-weight: 700;
  border-radius: 15px;
  padding: 4px 13px;
  color: #2084d8;
  margin-top: -4px;
  background-color: rgba(32, 132, 216, 0.15); }
  .url-filter__save:hover {
    background-color: rgba(32, 132, 216, 0.25); }
