.set-password {
  margin-top: 24px;
  min-height: 100vh;
  padding-bottom: 20vh; }
  @media (min-width: 720px) {
    .set-password {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -ms-flex-item-align: center;
          align-self: center; } }
  .set-password h3 {
    text-align: center;
    margin-bottom: 6px; }
  .set-password form {
    padding: 24px 18px;
    background-color: rgba(255, 255, 255, 0.9); }
    @media (min-width: 720px) {
      .set-password form {
        width: 440px; } }
  .set-password__item {
    max-width: 100%;
    padding: 18px 3px 6px 3px; }
    @media (min-width: 720px) {
      .set-password__item {
        width: 440px; } }
    .set-password__item .checkbox {
      margin-bottom: 6px; }
  .set-password__name-fields {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row; }
  .set-password__logo {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin: 12px 0; }
  .set-password__item, .set-password__title {
    -webkit-animation-delay: 440ms;
            animation-delay: 440ms;
    -webkit-animation: fall-down 600ms linear both;
            animation: fall-down 600ms linear both; }
    .set-password__item:nth-child(1), .set-password__title:nth-child(1) {
      -webkit-animation-delay: 140ms;
              animation-delay: 140ms; }
    .set-password__item:nth-child(2), .set-password__title:nth-child(2) {
      -webkit-animation-delay: 200ms;
              animation-delay: 200ms; }
    .set-password__item:nth-child(3), .set-password__title:nth-child(3) {
      -webkit-animation-delay: 260ms;
              animation-delay: 260ms; }
    .set-password__item:nth-child(4), .set-password__title:nth-child(4) {
      -webkit-animation-delay: 320ms;
              animation-delay: 320ms; }
    .set-password__item:nth-child(5), .set-password__title:nth-child(5) {
      -webkit-animation-delay: 380ms;
              animation-delay: 380ms; }
