.task-filters {
  color: #2d3644;
  padding: 24px;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  background-color: #fff; }
  .task-filters__title {
    font-size: 15px;
    margin-bottom: 6px; }
    @media screen and (min-width: 720px) {
      .task-filters__title {
        font-size: 16px; } }
  .task-filters__responses {
    font-size: 14px;
    margin-bottom: 24px;
    color: #646a75; }
  .task-filters .button {
    margin-bottom: 0;
    margin-top: 12px;
    border-width: 2px;
    border-color: rgba(32, 132, 216, 0.5); }
    .task-filters .button:active, .task-filters .button:hover {
      outline: none; }
  @media (min-width: 720px) {
    .task-filters__mobile-apply {
      display: none; } }

@-webkit-keyframes dropIn {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -10px, 0) scale(0.8);
            transform: translate3d(0, -10px, 0) scale(0.8); }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0) scale(1);
            transform: translate3d(0, 0, 0) scale(1); } }

@keyframes dropIn {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -10px, 0) scale(0.8);
            transform: translate3d(0, -10px, 0) scale(0.8); }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0) scale(1);
            transform: translate3d(0, 0, 0) scale(1); } }
